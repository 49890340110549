import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { StyledTabs, Card, Title, Content } from './styles'

import Boxes from '../../assets/box.png'

import 'aos/dist/aos.css';
import Aos from 'aos';
import { ButtonContact } from '../../pages/Home/BlocoUm/styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;

}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function BasicTabs() {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    Aos.init({ duration: 1500, once: true });
  }, [])

  function handleRedirect() {
    window.location.href = '/contato'
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ paddingLeft: "20px", borderBottom: 1, borderColor: '#ffffff' }}>
        <StyledTabs value={value} onChange={handleChange} aria-label="basic Styles example">
          <Tab label="Para você" />
          <Tab label="Empresas" />
          <Tab label="E-commerces" />
        </StyledTabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Card>
          <div className="content-text">
            <Title data-aos="slide-right">
              Envie com segurança sem sair de casa.
            </Title>
            <Content data-aos="slide-right" data-aos-duration="1200">
              Texto em desenvolvimento
            </Content>
            <ButtonContact
              onClick={handleRedirect}
              data-aos="fade-up"
              data-aos-duration="1100">
              entre em contato
            </ButtonContact>
          </div>
          <div className="content-image" data-aos="fade-down" data-aos-duration="500">
            <img src={Boxes} alt="" />
          </div>
        </Card>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Card>
          <div className="content-text">
            <Title data-aos="slide-right">
              Envie com segurança sem sair de casa.
            </Title>
            <Content data-aos="slide-right" data-aos-duration="1200">
              Texto em desenvolvimento
            </Content>
            <ButtonContact
              onClick={handleRedirect}
              data-aos="fade-up"
              data-aos-duration="1100">
              entre em contato
            </ButtonContact>
          </div>
          <div className="content-image" data-aos="fade-down" data-aos-duration="300">
            <img src={Boxes} alt="" />
          </div>
        </Card>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Card>
          <div className="content-text">
            <Title data-aos="slide-right">
              Envie com segurança sem sair de casa.
            </Title>
            <Content data-aos="slide-right" data-aos-duration="1200">
              Texto em desenvolvimento
            </Content>
            <ButtonContact
              onClick={handleRedirect}
              data-aos="fade-up"
              data-aos-duration="1100">
              entre em contato
            </ButtonContact>
          </div>
          <div className="content-image" data-aos="fade-down" data-aos-duration="300">
            <img src={Boxes} alt="" />
          </div>
        </Card>
      </TabPanel>
    </Box>
  );
}
