import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  padding-bottom: 30px;

`

export const Content = styled.div`
  width: 100%;
  max-width: 1440px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  .version-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .version-web {
    display: none;
  }

  @media(min-width: 919px ) {
    .version-mobile {
      display: none;
    }

    .version-web {
      display: flex;
      padding-top: 50px;
    }
  }
`

export const Title = styled.h1`
  font-size: 2.3rem;
  color: var(--text-blue);
  text-align: center;
`