import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';

import { BiMenu } from "react-icons/bi";

import { Container, StyledList, StyledListItem, StyledDivider, StyledButton } from './styles'

import { AiOutlineClose } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";

import Logo from '../../assets/logo.png';

import { useHistory } from 'react-router-dom';


type Anchor = 'left';

export default function MenuMobile() {
  const history = useHistory();

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <Box
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <StyledList>
        <Container>
          <div className="container-header">
            <img src={Logo} alt="Logo" />
            <AiOutlineClose size={20} />
          </div>
        </Container>

        <StyledListItem onClick={() => {
          history.push("/")
        }}>
          <div className="align">
            <a href="/"> inicio   </a>
            <IoIosArrowForward color='#FFCC2A' size={10} />
          </div>
        </StyledListItem>
        <StyledListItem >
          <div className="align">
            <a href="/#idBlocoSete"> sobre   </a>
            <IoIosArrowForward color='#FFCC2A' size={10} />
          </div>
        </StyledListItem>
        <StyledListItem >
          <div className="align">
            <a href="/#idBlocoDois"> soluções  </a>
            <IoIosArrowForward color='#FFCC2A' size={10} />
          </div>
        </StyledListItem>
        <StyledListItem >
          <div className="align">
            <a href="/#idBlocoNove"> trabalhe conosco</a>
            <IoIosArrowForward color='#FFCC2A' size={10} />
          </div>
        </StyledListItem>
        <StyledListItem >
          <div className="align">
            <a href="/contato"> entre em contato </a>
            <IoIosArrowForward color='#FFCC2A' size={10} />
          </div>
        </StyledListItem>
        <br />
        <StyledDivider />
        <div className="container-button">
          <StyledButton target="_blank" href="https://api.whatsapp.com/send?phone=551151997622"> cotação online </StyledButton>
        </div>
      </StyledList>
    </Box>
  );

  return (
    <div>
      {(['left'] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <BiMenu size={35} color="#FFCC2A" />
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
