import { Container, Content } from './styles'

import MyCarouselBlue from '../../../components/CarouselBlue'

export function BlocoSeis() {
  return (
    <Container>
      <Content>
        <MyCarouselBlue />
      </Content>
    </Container>
  )
}