import styled from 'styled-components'

import Carousel from "react-multi-carousel";

export const Container = styled.div`
  width: 100%;
  height: 539px;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  
  background: var(--background-one);

  .spaceParagraph p{
    display: flex;
    width: 100%;

    margin-bottom: 25px;
    
    font-size: 1.2rem;
  }

  @media(max-width:425px) {
    height: 725px;
  }
`

export const StyledCarousel = styled(Carousel)`
.react-multi-carousel-dot--active button{
  background-color: var(--background-two) !important;
  
  border: none;
}

.react-multi-carousel-dot button{
  border: none;

  background-color: #747474;
}

.react-multi-carousel-dot-list {
  position: absolute;
  bottom: 14px;
  width: 100%;
}
`