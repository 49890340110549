import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--background-one);
 
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`

export const Content = styled.div`
  width: 100%;
  max-width: 1440px;
  
  padding: 50px 0px 0px 0px;
  padding-left: 4%;

  display: flex;

  .web-text {
    width: 100%;
    max-width: 570px;

    display: flex;
    flex-direction: column;

    color: var(--text-blue);
  }

   strong {
    max-width: 138px;

    padding-bottom: 5px;
    margin-bottom: 50px;
    border-bottom: 7px solid var(--background-two);
    border-radius: 4px;

    font-size: 1.5rem;
  }

  .web-text h1 {
    line-height: 50px;
    margin-bottom: 43px;

    font-size: 3.1rem;
  }

  .web-image {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    margin-left: 20px;

    width: 100%;
  }

  .web-image img {
    width: 100%;
    max-width: 700px;
  }


  @media(min-width: 1440px) {
    .web-image img {
      border-top-right-radius: 5px;
    }
  }

  @media(max-width: 1250px) {
    .web-image {
      align-items: center;
    }

    .web-image img {
      border-bottom-left-radius: 160px;
    }
  }

  @media(max-width: 1050px) {
    justify-content: center;

    .web-image {
      display: none;
    }
  }
`