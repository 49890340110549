import SimpleAccordion from "../../../components/Accordion";
import BasicTabs from "../../../components/Tabs";

import { Container, Content, Title } from './styles'

export function BlocoTres() {

  return (
    <Container>
      <Content>
        <div className="version-mobile">
          <Title> Envie com segurança
            <br />
            sem sair de casa. </Title>
          <SimpleAccordion />
        </div>
        <div className="version-web">
          <BasicTabs />
        </div>
      </Content>
    </Container>
  )
}