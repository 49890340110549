import styled from 'styled-components'

import Carousel from "react-multi-carousel";

import EffectLogotipo from '../../assets/sombra.png'


export const Container = styled.div`
  width: 100%;
  height: 539px;

  display: flex;
  align-items: center;
  justify-content: center;
  
  background: var(--background-blue);

`

export const Card = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${EffectLogotipo});
  background-size: 400px;
  background-repeat: no-repeat;
  background-position: right center;

  padding: 61px 15px;

  @media(min-width: 768px) {
    padding: 61px 120px;


  }


`

export const StyledCarousel = styled(Carousel)`
.react-multi-carousel-dot--active button{
  background-color: var(--background-two);
  
  border: none;
}

.react-multi-carousel-dot button{
  border: none;
  

}

.react-multi-carousel-dot-list {
  position: absolute;
  bottom: 14px;
  width: 100%;
}
`

export const Title = styled.h1`
  font-size: 2rem;
  color: #FFFFFF;

`

export const Content = styled.p`
  font-size: 1.5em;
  
  color: #FFFFFF;
  line-height: 35px;
  font-weight: 400;
  
  width: 100%;
  max-width: 331px;

  margin-top: 50px;

  p {
    display: flex;
    align-items: center;
  }

  img {
    width: 35px;
  }

  @media(min-width: 768px) {
    font-size: 1.5em;
    max-width: 531px;

  }
`