import styled from 'styled-components'

import Tabs from '@mui/material/Tabs';


export const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: #FFCC2A !important;
  }

  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #000000 !important;
  }

  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-family: 'Kanit', sans-serif;
    font-size: 1.3rem;
    font-weight: 500;
    margin-right: 90px;
  }
`

export const Card = styled.div`
  width: 100%;
  
  display: flex;
  align-items: center;
  justify-content: space-around;

  .content-text {
    width: 100%;
  }

  .content-text button {
    margin-top: 50px;
  }
  
  .content-image {
    width: 100%;
    
    margin-bottom: -60px;
  }

  .content-image img {
    width: 100%;
    max-width: 636px;

  }
`

export const Title = styled.h1`
  width: 100%;
  max-width: 511px;

  font-size: 2.7rem;
  line-height: 45px;
`

export const Content = styled.p`
  width: 100%;
  max-width: 495px;

  margin-top: 36px;

  font-size: 1.2rem;
  line-height: 24px;
`