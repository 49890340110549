import { useEffect } from 'react';

import { Container, Content, GridCards, Card } from './styles'

import cube from '../../../assets/cubo.png'
import timer from '../../../assets/time.png'
import truck from '../../../assets/delivertruck.png'
import plane from '../../../assets/iconplane.png'

import MyCarousel from '../../../components/CarouselYellow'

import 'aos/dist/aos.css';
import Aos from 'aos';

export function BlocoQuatro() {
  useEffect(() => {
    Aos.init({ once: true });
  }, [])
  return (
    <Container>
      <Content>
        <h1> Levamos o transporte de cargas a sério </h1>
        <div className="responsive-web">
          <GridCards>
            <Card data-aos="fade-down" data-aos-duration="1600">
              <div className="logotipoEdit2">
                <img src={truck} alt="" />
              </div>
              <h1>Coletas urgentes</h1>
              <p>É urgente? Não tem problema
                Temos uma equipe dedicada a coletas urgentes, desde a retirada até a entrega dedicada ao seu pacote para chegar o mais rápido possível.</p>
            </Card>
            <Card data-aos="fade-down" data-aos-duration="2000">
              <div className="logotipo">
                <img src={cube} alt="" />
              </div>
              <h1>Coletas e entregas fracionado</h1>
              <p>Uma amostra? É pouca coisa?
                <br />
                Não tem problema,
                a solução é o frete fracionado,
                buscando o melhor custo beneficio pra você!</p>
            </Card>
            <Card data-aos="fade-down" data-aos-duration="1800">
              <div className="logotipoEdit">
                <img src={timer} alt="" />
              </div>
              <h1>Coletas pontuais</h1>
              <p>Nada como receber um pacote no horário marcado, criamos rotas especiais e práticas para garantir a sua entrega no horário combinado.</p>
            </Card>
            <Card data-aos="fade-down" data-aos-duration="1400">
              <div className="logotipo">
                <img src={plane} alt="" />
              </div>
              <h1>Serviços aéreos e redespachos</h1>
              <p>Uma maneira rápida e pratica para envios mais urgentes de suas cargas, contando com as melhores companhias aéreas para todo o Brasil.</p>
            </Card>
          </GridCards>
        </div>
        <div className="responsive-mobile">
          <MyCarousel />
        </div>
      </Content>
    </Container>
  )
}