import styled from "styled-components";

import { keyframes } from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  
  background: var(--background-two);
 
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .wrapper-image {
    display: flex;
    align-items: center;
    justify-content: center;
      
  }

  .wrapper-image img {
    width: 100%;
    max-width: 625px;
    margin: 100px 0;
  }

  @media(max-width: 1080px) {
    .wrapper-image img {
      max-width: 540px;
    }
  }

  @media(max-width: 939px) {
    .wrapper-image {
      display: none;
    }
  }

`

export const Content = styled.div`
  max-width: 1440px;
  flex-direction: column;

  display: flex;
  align-items: center;
  justify-content: center;
 

.mobile-image {
  display: none;
}


p {
  width: 100%;
  max-width: 390px;
  
  font-weight: 400;
  font-size: 1.4rem;
  
  margin-bottom: 36px;
}

@media(max-width: 496px) {
  padding: 20px;

  .mobile-image {
    display:none;
  }
}


@media(min-width: 496px) {
  .mobile-image {
    display:flex;
  }

  .mobile-image img{
    max-width: 400px;
  }

}

@media(min-width: 940px) {
  align-items: start;

  .mobile-image {
    display: none;
  }

  p {
    font-size: 1.2rem;
  }
}

@media(min-width: 1024px) {
  margin-top: 4rem;

}

@media(min-width: 1440px) {
  p {
    line-height: 1.5rem;

    max-width: 560px;
    font-size: 1.4rem;
  }

  margin-top: 2rem;


  .content-text {
    width: 495px;
    height: 0;
    line-height: 20px;

    margin-bottom: 50px;
  }
}

`

export const Title = styled.div`
  width: 100%;
  max-width: 694px;
  display: flex;
  align-items: start;
  flex-direction: column;
  margin-bottom: 40px;
  

  h1 {
    height: 30px;
    
    max-width: 400px;
    background-color: #222A35;
    margin-bottom: 14px;
    color: var(--text-white);
    font-weight: 700;

    display: flex;
    align-items: center;
    justify-content: start;
    
    font-size: 36px;

  }

  @media(max-width: 940px) {
    max-width: 390px;
  }

  @media(min-width: 1024px) {
    height: 100px;
    max-width: 465px;


    h1 {
      height: 35px;

      font-size: 44px;
    }
    
  } 

  @media(min-width: 1440px) {
    max-width: 580px;

    h1 { 
      font-size: 48px;

      font-weight: 700;
    }
  }

  @media(max-width: 940px) {
    padding-top: 20px;
    margin: 0px;
    align-items: center;

    h1 {
      font-size: 2.5rem;
    }
  }

  @media(max-width: 325px) {
    h1 {
      font-size: 33.6px;
    }
  }
`


export const Button = styled.a`
  width: 100%;
  max-width: 206px;
  height: 73px;
  
  border-radius: 10px;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;

  text-decoration: none;
  
  font-size: 1.2rem;
  font-weight: 700;

  background: var(--background-one);
  color: #FF6D18;

  @media(max-width: 939px) {
    max-width: 320px;
    margin-bottom: 20px;
  }

  @media(min-width: 940px) {
    width: 100%;
    max-width: 350px;
    height: 49px;

    font-size: 1.3rem;
    font-weight: 700;
  }

  @media(min-width: 1024px) {
    font-size: 1.11rem;
  }

  @media(min-width: 1440px) {
    height: 45px;
    
    margin-top: 270px;
    margin-right: 370px;
  }
`

const TranslateImage = keyframes`
  to {
    transform: translateX(1000px);
  }
`;

const TranslateText = keyframes`
  to {
    transform: translateX(-1000px);
  }
`;


export const AnimationImage = styled.div`
  animation: ${TranslateImage} 1.5s ease forwards;
`

export const AnimationText = styled.div`
  animation: ${TranslateText} 1.5s ease forwards;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`