import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  
  display: flex;
  justify-content: center;
  overflow: hidden;
  background-color: var(--background-one);
`


export const Content = styled.div`
  width: 100%;
  max-width: 1440px;
  
  padding: 50px 0;
  padding-left: 7%;
    

  display: flex;
  align-items: flex-start;


  .content-text h1 {
    width: 100%;
    max-width: 641px;
    font-size: 3rem;
    font-weight: 600;
    margin-top: 100px;

  }

  .content-text p {
    width: 100%;
    max-width: 600px;
    line-height: 29px;
    font-size: 17px;
    font-weight: 400;
    margin-top: 59px;
  }

  .buttons {
    width: 100%;
    max-width: 340px;
    margin-top: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .content-image {
    width: 100%;
    max-width: 677px;
    height: 734px;

    margin-left: -110px;
    
    transition: 2s;

  }

  @media(max-width: 1260px) {
    padding-left: 4%;

    .content-image {
      margin-left: -200px;
      transition: 1s;
    }
    .content-text h1 {
      width: 100%;
      max-width: 491px;
    }

    .content-text p {
      width: 100%;
      max-width: 450px;

    }
  }

  @media(max-width: 1000px) {
    .content-image {
      margin-left: -180px;
      transition: 1s;
      
    }
  }

  @media(max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    .content-image {
      width: 100%;
      height: 400px;
      margin-left: 0px;
      order: 1;
    }

    .content-text {
      order: 2;
    }

    .content-image img{
      width: 100%;
    }

    .content-text h1 {
      width: 100%;
      max-width: 530px;
      text-align: center;
      font-size: 2rem;
      margin-top: 10px;
    }

    .content-text p {
      width: 100%;
      max-width: 530px;
      text-align: center;
    }

    .buttons {
      width: 100%;
      max-width: 530px;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
    }

    .contact button {
      border-radius: 15px;
    }
  }

  @media(max-width: 500px) {
    .content-image {
      height: 300px;
    }

    .content-text h1 {
      width: 100%;
      max-width: 530px;
      text-align: center;
      font-size: 2rem;
      margin-top: 10px;
    }

    .content-text p {
      display: none;
    }

    .buttons {
      width: 100%;
      margin-top: 26px;
      max-width: 530px;
      flex-direction: column;
    }

    .contact button {
      border-radius: 15px;
    }

  }

  @media(min-width: 496px) {
    .hidden-text {
      display: none;
    }
  }

  @media(max-width: 496px) {
    padding: 10px;

    .mobile-text h1 {
      font-size: 1.2rem;
    }

    .buttons {
      width: 100%;
      max-width: 530px;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
    }

    .content-image {
      height: 270px;
    }
    
  }

  @media(max-width: 375px) {
    .content-image {
      height: 230px;
    }

  }

  @media(max-width: 370px) {
    .buttons button {
      width: 140px;
    }

    .content-image {
      height: 200px;
    }
  }

  a {
    width: 100%;
    max-width: 163px;

    height: 44px;
    border: none;
    border-radius: 50px;
    align-items: center;
    font-weight: 700;
    justify-content: center;
    font-size: 1.1rem;
    text-decoration: none;

    @media(max-width: 900px) {
      
      border-radius: 15px;
    }
  }


`

export const ButtonYellow = styled.button`
    width: 100%;
    max-width: 163px;

    height: 44px;
    border: none;
    border-radius: 50px;
    background: var(--background-two);
    align-items: center;
    font-weight: 700;
    justify-content: center;
    font-size: 1.1rem;
    text-decoration: none;

    @media(max-width: 900px) {
      
      border-radius: 15px;
    }
`

export const ButtonContact = styled.button`
    width: 100%;
    max-width: 163px;

    height: 44px;
    border: none;
    color: var(--text-white);
    border-radius: 50px;
    background: #6D7279;
    align-items: center;
    font-weight: 700;
    justify-content: center;
    font-size: 1.1rem;


    @media(max-width: 900px) {
      border-radius: 15px;
    }
  
`


