import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Kanit', sans-serif;
}

:root {

  --background-one: #fbfbfb; /* Background Branco */
  --background-blue: #222A35; /* Background Azul */
  --background-two: #FFCC2A; /* Background Amarelo */
  --background-gray: #FBFBFB; /* Background Cinza */

  --text-blue: #222A35; /* Texto azul */
  --text-white: #fff; /* Texto Branco */

  --title-orange: #FF853B; /* Texto Laranja */

  --gray-line: #E0D9D9; /* Linha do Footer */

}


@media (max-width: 1260px) {
  html {
    font-size: 93.75%;
  }
}


@media (max-width: 990px) {
  html {
    font-size: 87.5%;
  }
}

body,
input,
textarea,
button {
  font: 400 1rem sans-serif;
}

button {
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
}
`