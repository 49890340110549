import { BsTelephone } from 'react-icons/bs';
import { HiOutlineMail } from 'react-icons/hi';
import { Container } from './styles';


export default function Infopop() {

  return (
    <Container>
      <div className="content">
        <div className="title">
          <h1>
            Informações
          </h1>
        </div>
        <div className="infos">
          <p>
            <HiOutlineMail style={{ marginRight: "5px" }} />
            comercial@spotcargolog.com.br
          </p>
          <p>
            <BsTelephone style={{ marginRight: "5px" }} />
            (11) 51997622
          </p>
        </div>
        <div className="square">
        </div>
      </div>
    </Container>
  );
}