import { BlocoUm } from './BlocoUm';
import { BlocoDois } from './BlocoDois';
import { BlocoTres } from './BlocoTres';
import { BlocoQuatro } from './BlocoQuatro';
import { BlocoCinco } from './BlocoCinco';
import { BlocoSeis } from './BlocoSeis';
import { BlocoSete } from './BlocoSete';
import { BlocoOito } from './BlocoOito';
import { BlocoNove } from './BlocoNove';

import { Container, Content } from './styles'
import Footer from '../../components/Footer';
import Header from '../../components/Header';


export default function Home() {
  return (
    <Container>
      <Content>
        <Header />
        <BlocoUm />
        <BlocoDois />
        <BlocoSete />
        <BlocoQuatro />
        <BlocoCinco />
        <BlocoSeis />
        <BlocoTres />
        <BlocoOito />
        <BlocoNove />
        <Footer />
      </Content>
    </Container>
  )
}

