import Wpp from "../../assets/wpp.png"
import Email from "../../assets/email.png"
import { Container } from './styles';

export default function Info() {

  return (
    <Container>
      <div className="content">
        <div className="content-text">
          <h1>
            Informações
          </h1>
          <div className="infos">
            <div className="email">
              <img src={Email} alt="" />
              <p>
                comercial@spotcargolog.com.br
              </p>
            </div>
            <div className="wpp">
              <img src={Wpp} alt="" />
              <p>
                (11) 51997622
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container >
  );
}