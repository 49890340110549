import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;

  @media(min-width: 769px) {
    .mobile {
    display: none;
    }
  }

  @media(max-width: 855px) {
    flex-direction: column;
  }
`


export const Content = styled.div`

  width: 100%;
  max-width: 1440px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 30px 30px 10px 10px;
    

  .contact {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    
    @media(max-width: 1050px) {
    padding: 10px;

    display: flex;
    flex-direction: column;
    
    align-items: center;

    .container-info {
    max-width: 456px;
  }
}
  }

  .container-info {
    width: 100%;
    max-width: 498px;
  }

  .contact-content {
    display: flex;
    flex-direction: column;

    max-width: 456px;
    width: 100%;
  }

  .contact-message {
    max-width: 100%;
    height: 96px;

    display: flex;
    flex-direction: column;
  }

  .divisor {
    background: #FFDE3B;
    width: 180px;
    height: 3px;
  }

  .contact-form {
    width: 100%;
    max-width: 456px;
    
    display: flex;
    align-items: center;
  }

  .contact-message h1 {
    max-width: 500px;
    font-weight: 500;
    font-size: 45px;
    color: #313133;
  }

  .contact-message p {
    font-size: 20px;
    font-weight: 300;
    color: #7a7a7a;
  }

  form {
    width: 100%;

    display: flex;
    height: 100%;
    flex-direction: column;

  }

  input {
    max-width: 100%;
    display: flex;
    
    border: none;
    border-bottom: 1px solid #a7a3a07a;
    padding: 13px 13px;
    
  }

  .css-1869usk-MuiFormControl-root {
    max-width: 100%;
  }

  @media(min-width: 1441px) {
    margin: auto;

  }

  @media(max-width: 768px) {
    width: 100%;

    max-width: 768px;

    display: flex;

    box-sizing: border-box;

    .contact {
      flex-direction: column;
    }

    .contact-content {
      display: flex;
      align-items: center;
    }

    .contact-message {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }

  .contact-message p{
    font-size: 18px;
  }

    form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    width: 100%;
    height: 450px;
    }

    .contact-message h1 {
    font-weight: 500;
    font-size: 35px;
    color: #313133;
    }


    input {

    display: flex;

    width: 100%;
    
    border: none;
    border-bottom: 1px solid #a7a3a07a;
  }

  }
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 12px;

  max-width: 500px;
  height: 70px;

  font-size: 24px;
  font-weight: 600;

  background: #1e1e20;
  color: #ffffff;

  border: none;

  :hover {
    transition: 0.4s;

    background: #FFDE3B;
    color: #1E1E20;
  }

  @media(max-width:768px) {
  width: 100%;
  height: 60px;
  }
`