import { Container, Content, Information } from './styles'

import Logo from '../../assets/logo.png'
import { IoIosArrowForward } from "react-icons/io";
import { AiOutlineFacebook, AiOutlineInstagram } from "react-icons/ai";


export default function Footer() {
  return (
    <Container>
      <Content>
        <div className="org-grid">
          <div className="container-logo">
            <a href="/"><img src={Logo} alt="Logo" /> </a>
          </div>
          <div className="grids">
            <div className="grid">
              <div className="align">
                <a href="/#sobre-nos">
                  <p>sobre</p>
                </a>
                <IoIosArrowForward color='#FFCC2A' size={10} />
              </div>
              <div className="align">
                <a href="/#solucoes">
                  <p>soluções</p>
                </a>
                <IoIosArrowForward color='#FFCC2A' size={10} />
              </div>
            </div>
            <div className="grid">
              <div className="align">
                <a href="/contato">
                  <p>entre em contato</p>
                </a>
                <IoIosArrowForward color='#FFCC2A' size={10} />
              </div>
              <div className="align">
                <a href="/#trabalhe-conosco">
                  <p>trabalhe conosco</p>
                </a>
                <IoIosArrowForward color='#FFCC2A' size={10} />
              </div>
            </div>
          </div>
        </div>
      </Content>
      <hr />
      <div className="social">
        <p>redes sociais:</p>
        <a target="_blank" href="https://www.facebook.com/Spotcargo">
          <AiOutlineFacebook size={26} style={{ marginRight: "36px" }} />
        </a>
        <a target="_blank" href="https://www.instagram.com/Spotcargo">
          <AiOutlineInstagram size={26} />
        </a>
      </div>
      <Information>
        <p>
          © Copyright 2022 - Spot Cargo Logistica Integrada Ltda.
        </p>
      </Information>
    </Container>
  )
}