import { useEffect } from 'react';

import 'react-multi-carousel/lib/styles.css';

import { Container, Card, StyledCarousel, Title, Content } from './styles'

import 'aos/dist/aos.css';
import Aos from 'aos';
import Check from '../../assets/check.png'


export default function MyCarouselBlue() {
  useEffect(() => {
    Aos.init({ duration: 1500, once: true });
  }, [])
  return (
    <div
      style={{
        paddingBottom: '30px',
        position: 'relative'
      }}
    >
      <StyledCarousel

        additionalTransfrom={0}
        arrows={false}
        autoPlaySpeed={3000}
        centerMode={false}
        containerClass="container"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024
            },
            items: 1
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0
            },
            items: 1
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464
            },
            items: 1
          }
        }}
        showDots
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        <Container>
          <Card data-aos="fade-right">
            <Title>
              Missão
            </Title>
            <Content>
              Nossa missão é surpreender nossos clientes,
              buscando prestar o melhor serviço de transporte,
              com comprometimento e pontualidade,
              visando a qualidade e melhoria em todos os processos,
              sendo a melhor opção pro seu negocio.
            </Content>
          </Card>
        </Container>
        <Container>
          <Card data-aos="fade-right">
            <Title>
              Visão
            </Title>
            <Content>
              Visamos ser referência no mercado multimodal, expandindo nossos serviços para outros continentes.
            </Content>
          </Card>
        </Container>
        <Container>
          <Card>
            <Title>
              Valores
            </Title>
            <Content>

              <p>
                <img src={Check} alt="" style={{ marginRight: '15px' }} /> Transparência
              </p>
              <br />
              <p>
                <img src={Check} alt="" style={{ marginRight: '15px' }} /> Honestidade
              </p>
              <br />
              <p>
                <img src={Check} alt="" style={{ marginRight: '15px' }} /> Honra
              </p>
              <br />
              <p>
                <img src={Check} alt="" style={{ marginRight: '15px' }} /> Ética
              </p>

            </Content>
          </Card>

        </Container>

      </StyledCarousel>
    </div>
  )
}

