import { useEffect } from 'react';

import { Content, Title, Container, Button } from './styles';

import Plane from '../../../assets/plane.png';

import 'aos/dist/aos.css';
import Aos from 'aos';


export function BlocoDois() {
  useEffect(() => {
    Aos.init({ duration: 1500, once: true });
  }, [])
  return (
    <div>
      <Container id="solucoes">
        <div data-aos="slide-right" className="wrapper-image">
          <img src={Plane} alt="Plane-icon" />
        </div>
        <Content>
          <Title data-aos="slide-left" >
            <h1>Seus envios para </h1>
            <h1>todo o Brasil</h1>
          </Title>
          <div data-aos="slide-right" className="mobile-image">
            <img src={Plane} alt="Plane-icon" />
          </div>
          <div className="content-text" data-aos="slide-left" data-aos-duration="1600">
            <p><strong>Seja por terra ou pelo ar, </strong>
              nossa malha logística cobre o Brasil inteiro!
              Assim, suas vendas podem atravessar fronteiras por todo o território nacional!
              <br />
              <br />
              A Spot Cargo é garantia de velocidade e segurança! Coletamos seus envios e te mantemos informados sobre cada passo da sua entrega.
              Você fica despreocupado e garante qualidade de serviço sem sair de casa e sem pesar no seu bolso.
            </p>
          </div>
          <Button target="_blank" href="https://api.whatsapp.com/send?phone=551151997622" data-aos="fade-up"> Simule sua cotação agora mesmo! </Button>
        </Content>
      </Container>
    </div>
  )
}