import 'react-multi-carousel/lib/styles.css';

import { Container, Card, StyledCarousel } from './styles'

import cube from '../../assets/cubo.png'
import timer from '../../assets/time.png'
import truck from '../../assets/delivertruck.png'
import plane from '../../assets/iconplane.png'


export default function MyCarousel() {
  return (
    <div
      style={{
        paddingBottom: '30px',
        position: 'relative'
      }}
    >
      <StyledCarousel

        additionalTransfrom={0}
        arrows={false}
        autoPlaySpeed={3000}
        centerMode={false}
        containerClass="container"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024
            },
            items: 1
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0
            },
            items: 1
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464
            },
            items: 1
          }
        }}
        showDots
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        <Container>
          <Card>
            <div className="logotipo">
              <img src={truck} alt="" />
            </div>
            <h1>Coletas urgentes</h1>
            <p>É urgente? Não tem problema
              Temos uma equipe dedicada a coletas urgentes, desde a retirada até a entrega dedicada ao seu pacote para chegar o mais rápido possível.</p>
          </Card>
        </Container>
        <Container>
          <Card>
            <div className="logotipo">
              <img src={cube} alt="" />
            </div>
            <h1>Coletas e entregas fracionado</h1>
            <p>
              Uma amostra? É pouca coisa?
              <br />
              Não tem problema,
              a solução é o frete fracionado,
              buscando o melhor custo beneficio pra você!
            </p>
          </Card>
        </Container>
        <Container>
          <Card>
            <div className="logotipo">
              <img src={timer} alt="" />
            </div>
            <h1>Coletas pontuais</h1>
            <p>Nada como receber um pacote no horário marcado, criamos rotas especiais e práticas para garantir a sua entrega no horário combinado.</p>
          </Card>
        </Container>
        <Container>
          <Card>
            <div className="logotipo">
              <img src={plane} alt="" />
            </div>
            <h1>Serviços aéreos e redespachos</h1>
            <p>Uma maneira rápida e pratica para envios mais urgentes de suas cargas, contando com as melhores companhias aéreas para todo o Brasil.</p>
          </Card>
        </Container>
      </StyledCarousel>
    </div>
  )
}