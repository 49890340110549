import { Container, Content } from "./styles"

import Boy from "../../../assets/boy.png"
import CarouselText from "../../../components/CarouselText"

export function BlocoSete() {
  return (
    <div>
      <Container id="sobre-nos">
        <Content>
          <div className="web-text">
            <strong>Sobre Nós</strong>
            <h1>
              A solução do transporte
              que você precisa está aqui!
            </h1>
            <CarouselText />
          </div>
          <div className="web-image">
            <img src={Boy} alt="" />
          </div>
        </Content>
      </Container>
    </div>
  )
}