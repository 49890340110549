import { useEffect } from 'react';

import {
  Content,
  Container,
  ButtonYellow,
  ButtonContact
} from './styles'

import Car from '../../../assets/hugecar.png'

import 'aos/dist/aos.css';
import Aos from 'aos';

export function BlocoUm() {

  useEffect(() => {
    Aos.init({ duration: 1500, once: true });
  }, [])

  function handleRedirect() {
    window.location.href = '/contato'
  }
  return (
    <div>
      <Container id="idBlocoUm">
        <Content >
          <div className="content-text">
            <div className="mobile-text">
              <h1 data-aos="slide-right">
                Fretes rápidos
                do jeito que você precisa.
              </h1>
            </div>
            <p data-aos="slide-right" data-aos-duration="1400">Com a vasta experiência dos nossos idealizadores, chegamos para trazer grandes inovações ao ramo de fretes, garantindo o melhor custo benefício e qualidade de serviço.
              <br />
            </p>
            <div className="buttons">
              <a target="_blank" href="https://api.whatsapp.com/send?phone=551151997622">
                <ButtonYellow data-aos="fade-up">
                  cotação online
                </ButtonYellow>
              </a>
              <ButtonContact
                onClick={handleRedirect}
                data-aos="fade-up"
                data-aos-duration="1100">
                entre em contato
              </ButtonContact>
            </div>
          </div>
          <div className="content-image" data-aos="slide-left">
            <img src={Car} alt="" />
          </div>
        </Content>

      </Container>
    </div>
  )
}