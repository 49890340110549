import { useEffect, useRef, useState } from "react";

import { Form } from '@unform/web';
import { FormHandles, SubmitHandler } from "@unform/core";
import * as Yup from 'yup';

import Input from "../../../components/Input";

import mail from "../../../assets/mail.png"

import { Container, Content, Button } from "./styles";

import Aos from "aos";
import Info from "../../../components/Info";
import Modal from "../../../components/Modal";
import { Link } from "react-router-dom";
import TextArea from "../../../components/TextArea";
import { FormControl, MenuItem, Select, InputLabel, SelectChangeEvent } from "@mui/material";
import { api } from "../../../services/api";


interface FormData {
  name: string,
  email: string,
  mensagem: string
}

export function BlocoNove() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [options, setOptions] = useState('');
  const [error, setError] = useState(false)

  const formRef = useRef<FormHandles>(null);

  const closeModal = () => {
    setIsModalVisible(false)
  }

  const handleChange = (event: SelectChangeEvent) => {
    setOptions(event.target.value);
  };

  useEffect(() => {
    Aos.init({ duration: 1500, once: true });
  }, [])

  const handleSubmit: SubmitHandler<FormData> = async (data, { reset }) => {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('É necessário completar o campo !'),
        email: Yup.string()
          .email('Digite um e-mail válido')
          .required('O e-mail é obrigatório'),
        mensagem: Yup.string().required('Digite um comentário'),
        atuacoes: Yup.string().required('Obrigado')
      })

      await schema.validate(data, {
        abortEarly: false,
      })

      formRef.current.setErrors({});

      const response = {
        name: data.name,
        email: data.email,
        message: data.mensagem,
        acting: options,
      }

      api.post('sendWork', response);

      setIsModalVisible(true)
      reset();
    } catch (err) {
      if (options === '') {
        setError(true)
      }
      else {
        setError(false)
      }
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        })
        formRef.current.setErrors(errorMessages)
      }
    }
  }

  return (
    <Container id="trabalhe-conosco">
      <Content>
        <div className="contact">
          <div data-aos="slide-right" className="contact-content">
            <div className="contact-message">
              <h1>Trabalhe Conosco</h1>
              <div className="divisor">
              </div>
            </div>
            <div className="contact-form">
              <Form ref={formRef} onSubmit={handleSubmit}>
                <div style={{ marginTop: '8px', marginBottom: '8px' }}>
                  <Input name="name" type="text" placeholder=" Nome" error={false} />
                </div>
                <div style={{ marginTop: '8px', marginBottom: '8px' }}>
                  <Input name="email" placeholder=" Email" error={false} />
                </div>
                <div style={{ marginTop: '8px', marginBottom: '8px'}}>
                <FormControl variant="outlined" sx={{ m: 0, minWidth: 456 }}>
                <InputLabel id="demo-simple-select-standard-label">Área de interesse</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="atuacoes"
                    error={error}
                    value={options}
                    onChange={handleChange}
                    label="Área de interesse"
                  >
                    <MenuItem value="Nenhum">Nenhum</MenuItem>
                    <MenuItem value={'Colaborador'}>Colaborador</MenuItem>
                    <MenuItem value={'Quero agregar'}>Quero agregar</MenuItem>
                    <MenuItem value={'Vendedor externo'}>Vendedor externo</MenuItem>
                  </Select>
                </FormControl>
                {error && <span style={{ color: '#f00', fontSize: '13px', margin: '3px' }}> É necessário completar o campo ! </span>}
                </div>
                <TextArea name="mensagem" placeholder="Comentário" error={false} />

                <Button data-aos="fade-up" type="submit">Enviar</Button>
              </Form>
            </div>
          </div>
          <div data-aos="fade-left" className="container-info">
            <Info />
          </div>
        </div>
        {isModalVisible ? (
          <Modal variant='home'>
            <div className="responsive">
              <img src={mail} alt="mail" />
              <strong>Obrigado por nos contatar!</strong>
              <p>Recebemos sua mensagem.</p>
              <p>Entraremos em contato com você
                <br />
                imediatamente!</p>
            </div>
            <Link to="/#idBlocoUm">
              <button onClick={closeModal} data-aos="fade-up">
                Retornar a Página Inicial
              </button>
            </Link>
          </Modal>
        ) : null}
      </Content>
    </Container>
  );
}