import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background-blue);
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  margin: 70px 0;
  color: #FFFFFF;

  h3 {
    font-size: 64px;
    font-weight: 600;
  }


  @media(min-width: 320px) {
  max-width: 409px;
  height: 422px;

  
  h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
  }
}

@media(min-width: 720px) {
  max-width: 720px;

  h3 {
    font-size: 30px;
    font-weight: 500;
    line-height: 28px;
  }

  p {
    font-size: 22px;
    font-weight: 400;
  }

}

@media(min-width: 1024px) {
  max-width: 1024px;

  h3 {
    font-size: 64px;
    font-weight: 600;
    
  }

  p {
    font-size: 28px;
    font-weight: 400;
  }
}

 @media(min-width: 1440px) {
  display: flex;
  max-width: 1900px;
}
`

export const Title = styled.div`
  width: 100%;
  
  @media(min-width: 320px) {
  text-align: center;
  font-weight: 600;
}
`

export const Button = styled.button`
  


  @media(min-width: 320px) {
  justify-content: center;
  color: #222A35;
  width: 100%;
  max-width: 244px;
  height: 59px;
  border: none;
  border-radius: 10px;
  background: var(--background-two);
  font-weight: 600;
  font-size: 24px;
  }

  @media(min-width: 320px) {
  border-radius: 200px;

  }
`