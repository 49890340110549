import styled from "styled-components";


export const Container = styled.div`
  display: flex;

  margin-left: 20px;

  padding: 40px;

  width: 100%;
  max-width: 569px;
  height: 542px;
  
  align-items: center;

  border-radius: 5px;
 
  background: #1E1E20;

  .content {
    display: flex;
    flex-direction: column;
  }

  .content h1{
    display: flex;

    justify-content: flex-start;

  }

  .title {
    display: flex;

    padding-bottom: 40px;

    align-items: center;
  }

  .title h1{
    color: #e2e2e3;
    font-weight: 400;
  }
  
  .infos {
    display: flex;

    flex-direction: column;

    color: #FFFFFF;
  }

  .infos p {
    padding-bottom: 42px;;
  }

  p {
    display: flex;

    align-items: center;

    justify-content: flex-start;

    font-size: 1.2rem;
  }

  @media(max-width: 768px) {
    display: flex;

    width: 100%;
    max-width: 768px;
    height: 345px;
    
    margin: 0px;

    .title {
      max-width: 300px;
    }

    .infos {
      display: flex;
      flex-direction: column;

      align-items: center;
      
      max-width: 300px;
    }

    .content {
      display: flex;

      width: 100%;
    }
    
    .content h1 {
      width: 200px;
    }
  }

  @media(max-width:900px) {
    padding: 20px;
    max-width: 479px;

    p {
      width: 300px;
    }
  }

  @media(max-width:400px) {
    .infos {
      padding-left: 20px;
    }

  }
`
