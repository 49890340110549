import { useEffect, useRef, useState } from "react";

import { Form } from '@unform/web';
import { FormHandles, SubmitHandler } from "@unform/core";
import * as Yup from 'yup';

import Input from "../../components/Input";

import Header from "../../components/Header"

import { Container, Content, Button } from "./styles";

import Infopop from "../../components/PopInfo";

import Aos from "aos";
import Modal from "../../components/Modal";
import mail from "../../assets/mail.png"
import { Link } from "react-router-dom";
import TextArea from "../../components/TextArea";
import Footer from "../../components/Footer";
import { api } from "../../services/api";

interface FormData {
  name: string,
  email: string,
  phone: string,
  mensagem: string
}

export default function Contato() {
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 1500, once: true });
  }, [])

  const formRef = useRef<FormHandles>(null);

  const handleSubmit: SubmitHandler<FormData> = async (data, { reset }) => {

    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('O nome é obrigatório'),
        email: Yup.string()
          .email('Digite um e-mail válido')
          .required('O e-mail é obrigatório'),
        mensagem: Yup.string().required('Digite sua mensagem!'),
        phone: Yup.string().required('Digite um número para contato!')
      })

      await schema.validate(data, {
        abortEarly: false,
      })

      const response = {
        name: data.name,
        email: data.email,
        phone: data.phone,
        message: data.mensagem
      }

      api.post('sendContact', response);

      formRef.current.setErrors({});
      setIsModalVisible(true)
      
      reset();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        })

        formRef.current.setErrors(errorMessages)
      }
    }
  }

  return (
    <Container>
      <Header />
      <Content>
        <div className="contact">
          <div className="contact-content">
            <div className="contact-section">
              <div className="contact-message">
                <h1>Entre em contato</h1>
                <div className="divisor"></div>
                <p>Sinta-se livre para nos contatar
                  a qualquer hora, iremos lhe
                  retornar o mais rápido possível !
                </p>
              </div>
              <div className="contact-form">
                <Form ref={formRef} onSubmit={handleSubmit}>
                  <div style={{ marginTop: '8px', marginBottom: '8px'}}>
                    <Input name="name" type="text" placeholder=" Nome" error={false} />
                  </div>
                   <div style={{ marginTop: '8px', marginBottom: '8px'}}>
                    <Input name="email" placeholder=" Email" error={false} />
                  </div>
                  <div style={{ marginTop: '8px', marginBottom: '8px'}}>
                    <Input name="phone" placeholder="Seu contato (DDD + Número)" error={false} variant="phone"/>
                  </div>

                  <TextArea name="mensagem" placeholder="Comentário" error={false} />

                  <Button data-aos="fade-up" type="submit">Enviar</Button>
                </Form>
              </div>
            </div>
          </div>
          <Infopop />
        </div>
      </Content>
      
      {isModalVisible && isModalVisible ? (
        <Modal >
          <div className="responsive">
            <img src={mail} alt="mail" />
            <strong>Obrigado por nos contatar!</strong>
            <p>Recebemos sua mensagem.</p>
            <p>Entraremos em contato com você
              <br />
              imediatamente!</p>
          </div>
          <Link to="/">
            <button data-aos="fade-up">
              Retornar a Página Inicial
            </button>
          </Link>
        </Modal>
      ) : null}
    <Footer />
    </Container>
  );
}