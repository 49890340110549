import { useEffect, useRef, useState } from "react";
import { useField } from '@unform/core';

import { InputHTMLAttributes } from "react";

import TextField from '@mui/material/TextField';
import { Container } from "./styles";


interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  placeholder: string;
  type?: string;
  error: boolean;
  variant?: 'phone'

}

export default function Input({ name, placeholder, type, variant}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, registerField, defaultValue, error, clearError } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.value
      },
    })
  }, [fieldName, registerField]);

  const [card, setCard] = useState<String>();
  
  const handleChange = () => {
    console.log(inputRef.current, 'current')
    const cardValue = inputRef.current.value
      .replace(/\D/g, '')
      .match(/(\d{0,2})(\d{0,1})(\d{0,4})(\d{0,4})/);
      inputRef.current.value = !cardValue[2]
      ? cardValue[1]
      : `(${cardValue[1]}) ${cardValue[2]} ${`${
          cardValue[3] ? `${cardValue[3]}` : ''}`}${`-${cardValue[4] ? `${cardValue[4]}` : ''}`}`;
    const numbers = inputRef.current.value.replace(/(\D)/g, '');
    setCard(numbers);
  };

  useEffect(() => {
    handleChange();
  }, [card]);

  if (variant === 'phone') return (
    
    <Container>
      <TextField
        id="outlined-basic"
        label={placeholder}
        variant="outlined"
        inputRef={inputRef}
        onChange={handleChange}
        defaultValue={defaultValue}
        onFocus={clearError}
        error={error !== undefined}
      />
      {error && <span style={{ color: '#f00', fontSize: '13px', margin: '3px' }}>{error}</span>}
    </Container>
  )
  return (
    <Container>
      <TextField
        id="outlined-basic"
        label={placeholder}
        variant="outlined"
        inputRef={inputRef}
        defaultValue={defaultValue}
        type={type}
        onFocus={clearError}
        error={error !== undefined}
      />
      {error && <span style={{ color: '#f00', fontSize: '13px', margin: '3px' }}>{error}</span>}
    </Container>
  );
}