import { useEffect } from 'react';

import 'react-multi-carousel/lib/styles.css';

import { Container, StyledCarousel } from './styles'

import 'aos/dist/aos.css';
import Aos from 'aos';

export default function CarouselText() {
  useEffect(() => {
    Aos.init({ duration: 1500, once: true });
  }, [])
  return (
    <div
      style={{
        paddingBottom: '30px',
        position: 'relative'
      }}
    >
      <StyledCarousel
        additionalTransfrom={0}
        arrows={false}
        autoPlaySpeed={3000}
        centerMode={false}
        containerClass="container"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024
            },
            items: 1
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0
            },
            items: 1
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464
            },
            items: 1
          }
        }}
        showDots
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        <Container>
          <div className="spaceParagraph">
            <p>
              Com vasta experiencia no ramo multimodal de cargas, estamos prontos para atender suas necessidades com soluções rápidas e assertivas.
            </p>
            <p>
              A SPOTCARGO está atenta ao que tem de melhor no mercado, buscando constantemente a evolução em seus processos, contando com a melhor tecnologia, e com uma equipe qualificada para oferecer o melhor serviço aos nossos clientes, seja no modo aéreo, fracionado ou dedicado.
            </p>
            <p>
              Em todos os serviços, seja rodoviário ou aéreo, nossos clientes podem contar com rastreamento de suas cargas, receber notificações das entregas, eventuais divergências e tudo relacionado ao serviço prestado, tudo isso em tempo real, através de um sistema personalizado, feito pensando em atender as necessidades de cada cliente.
            </p>
          </div>
        </Container>
        <Container>
          <div className="spaceParagraph">
            <p>
              Pensando na segurança de sua carga, a SPOTCARGO, realiza o gerenciamento de risco, em parceria com a Buonny, empresa especialista do ramo, consultando cada motorista, antes de qualquer carregamento, podendo utilizar o rastreamento em tempo real, caso seja solicitado. Vale ressaltar que sua carga sai assegurada, por uma das maiores companhias de Seguro do País, afim de te dar a tranquilidade em qualquer embarque!
            </p>
            <p>
              Fundada em 2014, o objetivo principal da SPOT, e a satisfação de seus clientes, com uma prestação de serviço diferenciada, desde o primeiro contato, ate a entrega final.
            </p>
            <p>
              Por fim, somos uma Empresa que levamos o transporte a serio, pois sabemos que a logística e transporte, e um braço forte que movimenta o nosso Pais, e fazemos questão de realizar nosso trabalho com sucesso, contribuindo para o crescimento do seu negocio!
            </p>
          </div>
        </Container>

      </StyledCarousel>
    </div>
  )
}

