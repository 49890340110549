import styled from 'styled-components'

export const Container = styled.div`
 display: flex;
 align-items: center;
 width: 100%;

`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .content-text {
    width: 100%;
    max-width: 714px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .content-image {
    width: 100%;
    max-width: 863px;
    height: 100%;
    object-fit: cover;
  }

  img {
    width: 100%;
  }

  h1 {
    
  }

  p {
    width: 100%;
    max-width: 494px;
    font-size: 18px;
    font-weight: 400;
    color: #222A35;
  }

  @media(max-width: 1439px) {
    .content-text {
      padding-top: 40px;
      align-items: flex-start;
    }
}

  @media(max-width: 1023px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;



  p {
    text-align: center;
    width: 100%;

    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    height: 200px;
  }

  .content-image {
    width: 100%;
    max-width: 1024px;

    display: flex;
    align-items: center;
    justify-content: center;

  }

  img {
    width: 100%;
    object-fit: cover;
  }

  .content-text {
      align-items: center;
    }
}
`

export const Title = styled.h1`
  color: #222A35;
  font-weight: 600;
  font-size: 3.2rem;
  width: 100%;
  max-width: 570px;
  letter-spacing: 0.03em;
  margin-bottom: 40px;

  line-height: 60px;

  @media(max-width: 1023px) {
    font-size: 2.5rem;
    margin-bottom: 0px;

    text-align: center;
    
  }

`