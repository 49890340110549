import styled from 'styled-components'

import Accordion from '@mui/material/Accordion';

export const StyledAccordion = styled(Accordion)`

  .MuiAccordionSummary-root {
    background: var(--background-two) !important;
    margin-bottom: 2px;
  }

  .MuiTypography-body1 {
    font-family: 'Kanit', sans-serif;

  }

`
