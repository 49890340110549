import styled from 'styled-components'

import Carousel from "react-multi-carousel";


export const Container = styled.div`
  width: 100%;
  height: 533px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  background: var(--background-two);
`

export const Card = styled.div`
  width: 100%;
  max-width: 287px;
  height: 362px;
  border-radius: 25px;
  padding: 3rem 1.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--background-one);
  .logotipo {
    width: 100%;
  }
  .logotipo img {
    width: 100%;
    max-width: 55px;
  }
  h1 {
    width: 100%;
    font-size: 1.4rem;
    line-height: 30px;
    font-weight: 600;
    
    text-align: start;
   
    margin-top: 23px;
    margin-bottom: 12px;
    color: var(--text-blue)
  }
  p {
    width: 100%;
    
    font-size: 1.1rem;
    line-height: 21px;
  }
`

export const StyledCarousel = styled(Carousel)`
.react-multi-carousel-dot--active button{
  background-color: #182632;
  border: none;
}
.react-multi-carousel-dot button{
  border: none;
}
.react-multi-carousel-dot-list {
  position: absolute;
  bottom: 34px;
  width: 100%;
}
`