import styled from "styled-components";


export const Container = styled.div`
  display: flex;
  
  width: 100%;
  max-width: 498px;
  height: 350px;
  
  align-items: center;

  border-radius: 5px;
 
  background: #000;

  .content {
    display: flex;

    width: 100%;
    flex-direction: column;
  }

  .content h1{
    display: flex;
  }

  .content-text {
    display: flex;
    flex-direction: column;

    justify-content: space-between;

    padding: 40px;
    width: 100%;
  }

   h1 {
    color: #fff;
    font-weight: 400;

    font-size: 2rem;
  }
  
  .infos {
    display: flex;

    width: 100%;

    flex-direction: column;

    color: #a6a6a7;
  }

  .infos p {
  display: flex;

  align-items: center;

  color: #fff;

  font-size: 1.1rem;
  }

  .infos .email {
    display: flex;
    padding-top: 20px;
    align-items: center;
  }

  .infos .email p {
    margin-left: 10px;
  }

  .infos .wpp {
    display: flex;
    padding-top: 50px;
    align-items: center;
  }

  .infos .wpp p {
    margin-left: 10px;
  }

  @media(max-width: 1050px) {
    display: flex;

    width: 100%;
    height: 245px;
    
    margin: 0px;

    .title {
      height: 80px;
    }

    .infos {
      display: flex;
      flex-direction: column;
      
      height: 150px;
    }

    .content {
      display: flex;
    }
    
    .content h1 {
      width: 200px;
    }
  }

  @media(max-width:900px) {
    max-width: 479px;

    p {
      width: 300px;
    }
  }
  @media(max-width:450px) {
    .content-text {
      padding: 20px;
    }
  }


  @media(max-width:350px) {
    .content-text {
      padding: 10px;
    }

    .infos .email p {
    margin-left: 5px;
  }
  }
`
