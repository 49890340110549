import { useEffect, useRef } from "react";
import { useField } from '@unform/core';
import styled from "styled-components";


import { InputHTMLAttributes } from "react";

import TextField from '@mui/material/TextField';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  placeholder: string;
  error: boolean;

}

export default function TextArea({ name, placeholder }: Props) {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, registerField, defaultValue, error, clearError } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.value
      }
    })
  }, [fieldName, registerField]);

  return (
    <Container>
      <TextField 
        name={name}
        error={error !== undefined}
        
        id="outlined-multiline-static"
        defaultValue={defaultValue}
        onFocus={clearError}
        inputRef={inputRef}

        placeholder={placeholder}
        multiline
        rows={2}
      />
      {error && <span style={{ color: '#f00', fontSize: '13px', margin: '5px' }}>{error}</span>}
    </Container>
  );
}


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`