import { useEffect } from 'react';

import { Container, Title, Content, Button } from './styles';

import 'aos/dist/aos.css';
import Aos from 'aos';
import { Link } from 'react-router-dom';

export function BlocoOito() {
  useEffect(() => {
    Aos.init({ duration: 1500, once: true });
  }, [])
  return (
    <div>
      <Container>
        <Content>
          <Title data-aos="fade-up" data-aos-duration="1100">
            <h3>Ficou com alguma dúvida ?</h3>
          </Title>
          <p data-aos="fade-up" data-aos-duration="1100">
            Precisa de algo mais personalizado ?<br />
            A gente te ajuda, entre em contato com nossa
            equipe especializada.
          </p>
          <div>
            <Link to="/contato">
              <Button data-aos="fade-up" data-aos-duration="1100">Entre em contato</Button>
            </Link>
          </div>
        </Content>
      </Container>
    </div>
  )
}