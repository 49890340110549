import { useEffect } from 'react';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import { BiPlusCircle } from "react-icons/bi";

import { StyledAccordion } from './styles'

import 'aos/dist/aos.css';
import Aos from 'aos';
import { useState } from 'react';

export default function SimpleAccordion() {
  const [expanded, setExpanded] = useState<string | false>('panel1');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  useEffect(() => {
    Aos.init({ duration: 1500, once: true });
  }, [])
  return (
    <div>
      <StyledAccordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary
          expandIcon={<BiPlusCircle />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography >Para você</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Texto em desenvolvimento
          </Typography>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion>
        <AccordionSummary
          expandIcon={<BiPlusCircle />}
          aria-controls="panel1a-content"
          id="panel1a-header"


        >
          <Typography>Empresas</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Texto em desenvolvimento
          </Typography>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion>
        <AccordionSummary
          expandIcon={<BiPlusCircle />}
          aria-controls="panel1a-content"
          id="panel1a-header"

        >
          <Typography>E-commerce</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Texto em desenvolvimento
          </Typography>
        </AccordionDetails>
      </StyledAccordion>
    </div>
  );
}
