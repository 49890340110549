import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  background-color: var(--background-one);

`
export const Content = styled.div`
  width: 100%;
  max-width: 1440px;

  padding: 50px 1%;
  font-weight: 500;

  display: flex;
  justify-content: space-between;

  .menu-responsive {
    width: 100%;
    max-width: 50px;
    display: flex;
    align-items: flex-end;
  }

  .menu-responsive button {
    background: none;
  }

  .menu-horizontal {
    width: 100%;
    max-width: 50rem;
    height: 60px;
    justify-content: space-between;
    display: none;
    align-items: center;
  }

  .menu-horizontal button {
    width: 100%;
    max-width: 200px;
    height: 44px;
    border: none;
    border-radius: 50px;

    background: var(--background-two);
    display: flex;
    align-items: center;
    font-weight: 700;
    justify-content: center;
    font-size: 1rem;
  }


  .align {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: -5px;
    font-size: 18px;
  }

  .align a {
    margin-bottom: 4px;
    padding-right: 2px;
  }

  .alignbutton {
    width: 100%;
    max-width: 200px;
    height: 44px;
    border: none;
    border-radius: 50px;

    background: var(--background-two);
    display: flex;
    align-items: center;
    font-weight: 700;
    justify-content: center;
    font-size: 1rem;
  }

  .container-logo {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 20px;
  }

  .container-logo img {
    width: 100%;
    max-width: 244px;
  }

  @media(min-width: 940px) {
    justify-content: space-around;

    animation: none;

    .menu-horizontal {
      display: flex;

    }
    .menu-responsive {
      display: none;
    }
  }
`

