import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: var(--background-blue);

`

export const Content = styled.div`
  width: 100%;
  max-width: 1440px;
  
  padding-bottom: 30px;
  
`