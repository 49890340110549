import { useEffect } from 'react';

import { Content, Title, Container } from './styles';

import Deliver from '../../../assets/deliver-box.png';

import 'aos/dist/aos.css';
import Aos from 'aos';

export function BlocoCinco() {
  useEffect(() => {
    Aos.init({ duration: 1500, once: true });
  }, [])
  return (
    <div>
      <Container>
        <Content>
          <div className="content-image" data-aos="slide-right" data-aos-duration="1400">
            <img src={Deliver} alt="box" />
          </div>
          <div className="content-text">
            <Title data-aos="slide-left" data-aos-duration="1300">
              Coletas e entregas
              porta a porta
            </Title>
            <p data-aos="slide-left" >
              É mais prático e mais seguro, nosso veiculo dedicado vai
              até seu endereço para retirar seus pacotes.
              Sem custo extra ou limitações de pacote.
            </p>
          </div>
        </Content>
      </Container>
    </div>
  )
}

