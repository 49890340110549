import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;

  .modalHome {
    display: flex;
    
    justify-content: center;
    position: absolute;
    bottom: 160px;
    
    width: 100%;
    height: 732px;
    padding: 20px;
  }

  .modalContact {
    display: flex;
    align-items: center;
    justify-content: center;
    
    width: 100%;
    height: 732px;
    padding: 20px;
  }


`


export const Content = styled.div`
  background: #fff;
  color: #000;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 10px;

  width: 100%;
  max-width: 772px;
  height: 100%;

  padding: 10px;

  .responsive {
    display: flex;
    flex-direction: column;

    max-width: 100%;
  }

  strong {
    max-width: 441px;
    font-size: 2rem;
    color: #1E1E20;
  }

  p {
    max-width: 441px;

    text-align: center;

    max-width: 100%;
    font-size: 1.4rem;
    color: #656565;
  }

  button {
    width: 100%;
    max-width: 300px;

    padding: 18px;

    height: 60px;
    border: none;
    border-radius: 50px;

    margin-top: 30px;

    background: var(--background-two);

    display: flex;

    align-items: center;

    font-weight: 700;

    justify-content: center;

    font-size: 1.1rem;

    text-decoration: none;
  }

@media(max-width: 900px) {

  button {
    border-radius: 15px;
  }
}


`