import { useEffect } from 'react';

import { IoIosArrowForward } from "react-icons/io";

import MenuMobile from '../MenuMobile'

import Logo from '../../assets/logo.png'

import { Container, Content } from "./styles"

import 'aos/dist/aos.css';
import Aos from 'aos';

export default function Header() {
    useEffect(() => {
        Aos.init({ duration: 1500, once: true });
    }, [])
    return (
        <Container>
            <Content>
                <div className="container-logo" data-aos="fade-down">
                    <a href="/"> <img src={Logo} alt="Logo" /> </a>
                </div>
                <div className="menu-horizontal">
                    <div className="align" data-aos="fade-down" data-aos-duration="1400">
                        <a href="/#sobre-nos"> sobre   </a>
                        <IoIosArrowForward color='#FFCC2A' size={10} />
                    </div>
                    <div className="align" data-aos="fade-down" data-aos-duration="1300">
                        <a href="/#solucoes"> soluções   </a>
                        <IoIosArrowForward color='#FFCC2A' size={10} />
                    </div>
                    <div className="align" data-aos="fade-down" data-aos-duration="1100">
                        <a href="/contato"> entre em contato   </a>
                        <IoIosArrowForward color='#FFCC2A' size={10} />
                    </div>
                    <div className="align" data-aos="fade-down" data-aos-duration="1100">
                        <a href="/#trabalhe-conosco"> trabalhe conosco   </a>
                        <IoIosArrowForward color='#FFCC2A' size={10} />
                    </div>
                    <div className="alignbutton">
                        <a target="_blank" href="https://api.whatsapp.com/send?phone=551151997622">
                            <button>cotação online</button>
                        </a>
                    </div>
                </div>
                <div className="menu-responsive">
                    <MenuMobile />
                </div>
            </Content>

        </Container >
    )

}