import React from 'react';

import { Switch, Route } from 'react-router-dom';

import Home from '../pages/Home'
import Contato from '../pages/Contato';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" component={Home} exact />
      <Route path="/contato" component={Contato} exact />
    </Switch>
  )
}

export default Routes;