import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 294px;
  background-color: #FBFBFB;

  .container-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    
  }

  img {
    width: 100%;
    max-width: 244px;

    padding-right: 40px;
  }

  hr {
    width: 100%;
    border: 0.5px solid rgb(245 0 87 / 4%);
  }

  .social {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 20px;

    font-size: 1.1rem;
  }

  .social p {
    padding-right: 36px;
  }

  @media(min-width: 940px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    hr {
      width: 75%;
      border: 0.5px solid rgb(245 0 87 / 4%);
      
      margin-top: 0px;
    }
  }


`
export const Content = styled.div`
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;

  .align {
    display: flex;
    align-items: center;
    justify-content: center;
    
    font-size: 18px;
  }

  svg {
    margin-top: 3px;
  }

  .org-grid {
    padding: 10px;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .grid {
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
  }

  .grids {
    padding-top: 20px;
    display: flex;
    justify-content: space-around;
  }

  @media(min-width: 940px) {
    .org-grid {
      padding: 50px 0px;
      flex-direction: row;
    }

    .grid {
      display: flex;
      flex-direction: row;
    }

    .align {
      padding-right: 50px;
  }
  }
`

export const Information = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-size: 0.8rem;
    color: #E0D9D9;
    margin-bottom: 19px;

  }
`