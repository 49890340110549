import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  box-sizing: border-box;

`

export const Content = styled.div`
  width: 100%;
  position: relative;
  flex-direction: column;
  display: flex;
  justify-content: center;
  overflow: hidden;

`