import { Container, Content } from "./styles";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: any;
  variant?: 'home'
}

export default function Modal({ children, variant }: Props) {
  if (variant === 'home') return (
      <Container>
        <div className="modalHome">
          <Content>
            {children}
          </Content>
        </div>
      </Container>
  )
  return (
      <Container>
        <div className="modalContact">
          <Content>
            {children}
          </Content>
        </div>
      </Container>


  )

};
