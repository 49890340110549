import styled from 'styled-components'

import { ListItem, Divider, List } from '@mui/material'


export const Container = styled.div`
  width: 100%;
  padding: 30px 14px;

  box-sizing: border-box;

  .container-header {
    display: flex;
    align-items: center;
    
    justify-content: space-between;
  }

  .container-header img {
    display: flex;
    align-items: center;
    
    justify-content: space-between;

    width: 100%;
    max-width: 188px;
    padding-right: 20px;
  }

`

export const StyledListItem = styled(ListItem)`
  background: #f9f9f9;
  height: 44px;
  margin-top: 5px !important;

  span {
    font-size: 16px;
    color: #000000;
  }
  #ContainerMenu .MuiListItem-root .Mui-disabled {
    opacity: 0.2 !important;
    
  }
`

export const StyledDivider = styled(Divider)`
  background: #ffffff;
  margin-bottom: 40px !important;

`

export const StyledButton = styled.a`
    width: 100%;
    max-width: 200px;
    height: 44px;
    border: none;
    border-radius: 10px;

    text-decoration: none;

    background: var(--background-two);
    display: flex;
    align-items: center;
    font-weight: 700;
    justify-content: center;
    font-size: 1.2rem;
`

export const StyledList = styled(List)`
  .container-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`