import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: var(--background-two);
`

export const Content = styled.div`
  width: 100%;
  max-width: 1440px;
  
  padding-bottom: 30px;
  .responsive-mobile {
    display: auto;
  }
  .responsive-web {
    display: none;
  }
  h1 {
    text-align: center;
    color: #222A35;
    font-size: 2.5rem;
    margin-top: 42px;
  }
  @media(min-width: 900px) {
    .responsive-mobile {
      display: none;
    }
    .responsive-web {
      display: flex;
      flex-direction: column;
    }
  }
  
`

export const GridCards = styled.div`
  width: 100%;
  
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Card = styled.div`
  width: 100%;
  max-width: 287px;
  height: 362px;
  border-radius: 25px;
  padding: 3rem 1.7rem;

  margin: 20px 20px 0 20px;


  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--background-one);
  
  .logotipo {
    width: 100%;
  }
  .logotipoEdit {
    width: 100%;
    margin-top: -30px;
  }
  .logotipoEdit2 {
    width: 100%;
    margin-top: -10px;
  }

  .logotipoEdit2 {
    width: 100%;
    margin-top: 10px;
  }

  .logotipoEdit3 {
    width: 100%;
    max-width: 55px;
  }

  .logotipoEdit img {
    width: 100%;
    max-width: 55px;
  }
  .logotipo img {
    width: 100%;
    max-width: 55px;
  }
  h1 {
    width: 100%;
    font-size: 1.4rem;
    line-height: 30px;
    font-weight: 600;
    
    text-align: start;
   
    margin-top: 23px;
    margin-bottom: 12px;
    color: var(--text-blue)
  }
  p {
    width: 100%;
    
    font-size: 0.9rem;
    line-height: 21px;
  }
`